/* ==========================================================================
   Skin
   ========================================================================== */

/* Fonts */
$serif              : "Merriweather", Georgia, Times, serif;
$sans-serif         : "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
$header-font-family : $serif;

/* Colors */
$background-color: #eeeeee;
$text-color: #333333;
$muted-text-color: #444444;
$primary-color:  #387f41;
$border-color: darken($primary-color, 25%);
$footer-background-color: darken($primary-color, 25%);
$masthead-background-color: lighten($primary-color, 60%);
$link-color: darken($primary-color, 10%);
$masthead-link-color: $text-color;
$masthead-link-color-hover: $text-color;
$navicon-link-color-hover: mix(#fff, $text-color, 80%);
