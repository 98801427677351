/* ==========================================================================
   Overrides
   ========================================================================== */

.masthead {
  background-color: $masthead-background-color;
  
  .greedy-nav {
    background-color: $masthead-background-color;
  }
}

.page__footer {
  color: #fff;
  
  .social-icons {
    .far, .fab {
      color: #fff;
    }
  }
}
